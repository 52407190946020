
/**
 * Get user email on signup and populate the mailchimp form
 */

const signupEmailInput = document.querySelector('.js-signup-email-input')

if(signupEmailInput) {

  signupEmailInput.addEventListener('blur', function(e) {
    const email = signupEmailInput.value

    if(!email) return

    sessionStorage.setItem('email', email)

  })
}

// Autopopulate email address
const newslettterSignupForm = document.querySelector('.mc4wp-form')

if (newslettterSignupForm) {
  const emailFromStorage = sessionStorage.getItem('email')

  if(emailFromStorage) {
    const emailInput = newslettterSignupForm.querySelector('input[name="EMAIL"]')
    emailInput.value = emailFromStorage
  }
}


/**
 * Toggle body class when nav open on mobile
 * so we can add a background color
 */

const mainNav = document.getElementById('headerNav')

mainNav.addEventListener('show.bs.collapse', function (e) {
  document.body.classList.add('js-main-nav-opening')
})
mainNav.addEventListener('hide.bs.collapse', function (e) {
  document.body.classList.remove('js-main-nav-opening')
})

/* Animate on Scroll
============================= */

AOS.init()
